body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.grecaptcha-badge {
  visibility: hidden;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.markdown a {
  color: inherit;
}
